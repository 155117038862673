import React from 'react'

import { Mode, TransportSubmode, Leg } from '../../../types'

import {
    BusIcon,
    TrainIcon,
    TramIcon,
    FerryIcon,
    PlaneIcon,
    WalkingIcon,
    SubwayIcon,
} from '@entur/icons'

export function getTransportIcon(mode: string) {
    switch (mode) {
        case 'bus':
            return <BusIcon />
        case 'rail':
            return <TrainIcon />
        case 'tram':
            return <TramIcon />
        case 'water':
            return <FerryIcon />
        case 'air':
            return <PlaneIcon />
        case 'metro':
            return <SubwayIcon />
        case 'foot':
            return <WalkingIcon />
        default:
            return null
    }
}

function getHumanReadableNameForMode(
    mode: string,
    subMode?: TransportSubmode | null,
): string {
    switch (subMode) {
        case TransportSubmode.ShuttleBus:
            return 'Shuttlebuss'
        case TransportSubmode.SchoolBus:
            return 'Skolebuss'
        case TransportSubmode.RailReplacementBus:
            return 'Buss for tog'
        case TransportSubmode.AirportLinkBus:
            return 'Flybuss'
        case TransportSubmode.InternationalCoach:
        case TransportSubmode.NationalCoach:
            return 'ekspressbuss'
        case TransportSubmode.CityTram:
            return 'Bybanen'
        case TransportSubmode.Local:
            return 'Lokaltog'
        case TransportSubmode.TouristRailway:
            return 'Museumstog'
        case TransportSubmode.InternationalCarFerry:
        case TransportSubmode.NationalCarFerry:
            return 'Skip'
        case TransportSubmode.LocalCarFerry:
        case TransportSubmode.HighSpeedVehicleService:
            return 'Bilferje'
        case TransportSubmode.SightseeingService:
            return 'Turistbåt'
        case TransportSubmode.HighSpeedPassengerService:
            return 'Hurtigbåt'
        case TransportSubmode.HelicopterService:
            return 'Helikopter'
        case TransportSubmode.Telecabin:
            return 'Taubane'
        case TransportSubmode.Funicular:
            return 'Bergbane'
    }

    switch (mode) {
        case Mode.Bus:
            return 'Buss'
        case Mode.Tram:
            return 'Trikk'
        case Mode.Rail:
            return 'Tog'
        case Mode.Metro:
            return 'T-bane'
        case Mode.Water:
            return 'Båt'
        case Mode.Air:
            return 'Fly'
        default:
            return ''
    }
}

export function getAuthorityDescription(leg: Leg) {
    const authorityName =
        leg.mode === Mode.Air ? leg.operator?.name : leg.authority?.name

    return `${getHumanReadableNameForMode(
        leg.mode,
        leg.transportSubmode,
    )} med ${authorityName}`
}
