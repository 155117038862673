import React from 'react'

import { Heading2, Heading3, SmallText } from '@entur/typography'

import { LegLine, TravelTag } from '@entur/travel'
import { colors } from '@entur/tokens'
import {
    BusIcon,
    CarIcon,
    TrainIcon,
    TramIcon,
    FerryIcon,
    PlaneIcon,
    WalkingIcon,
    SubwayIcon,
    DestinationIcon,
} from '@entur/icons'

import { formatTime } from '../../../utils/time'
import { TripPattern } from '../../../types'

import './styles.css'

interface Props {
    tripPatterns: TripPattern[]
}

function getTransportIcon(mode: string) {
    switch (mode) {
        case 'bus':
            return <BusIcon />
        case 'car':
            return <CarIcon />
        case 'rail':
            return <TrainIcon />
        case 'tram':
            return <TramIcon />
        case 'water':
            return <FerryIcon />
        case 'air':
            return <PlaneIcon />
        case 'metro':
            return <SubwayIcon />
        case 'foot':
            return <WalkingIcon />
        default:
            return null
    }
}

export default function TravelResult({ tripPatterns }: Props) {
    return (
        <div className="result">
            <Heading2>Reiseresultat</Heading2>

            {tripPatterns.map((tripPattern) => (
                <div key={tripPattern.id} className="result__trip-pattern">
                    <div className="result__trip-pattern-header">
                        <Heading3 margin="none">
                            Fra {tripPattern.legs[0]?.fromPlace?.name}
                        </Heading3>

                        <div>
                            <SmallText>
                                {tripPattern.duration &&
                                    Math.round(tripPattern.duration / 60)}{' '}
                                min
                            </SmallText>
                        </div>
                    </div>

                    <div className="result__legs">
                        {tripPattern.legs.map((leg) => {
                            return (
                                <div
                                    key={leg.aimedStartTime}
                                    className="result__leg"
                                >
                                    <TravelTag>
                                        {getTransportIcon(leg.mode)}
                                        {leg.line?.publicCode}
                                    </TravelTag>

                                    <div>
                                        <SmallText>
                                            {formatTime(leg.aimedStartTime)}
                                        </SmallText>
                                    </div>
                                </div>
                            )
                        })}
                        <LegLine
                            className="result__leg-line"
                            color={colors.greys.grey20}
                            pattern="line"
                            direction="horizontal"
                        />

                        <div>
                            <TravelTag>
                                <DestinationIcon />
                            </TravelTag>

                            <div>
                                <SmallText>
                                    {formatTime(tripPattern.expectedEndTime)}
                                </SmallText>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}
