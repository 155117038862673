import React, { ReactNode } from 'react'

import { Tag } from '@entur/layout'
import {
    EmphasizedText,
    SubParagraph,
    StrongText,
    Paragraph,
    Link,
} from '@entur/typography'

import { Feedback, FeedbackPage } from '../../types'

import { formatDateTimeLong } from '../../utils/time'

import './styles.css'

function getSentFrom(page?: FeedbackPage): string {
    switch (page) {
        case 'TravelResult':
            return 'Reisesøk'
        case 'TravelDetails':
            return 'Reisedetaljer'
        case 'MyDetails':
            return 'Min profil'
        case 'Home':
            return 'Forsiden'
        default:
            return page || 'ukjent side'
    }
}

export default function Message({ feedback }: Props) {
    const {
        anonymous,
        createdAt,
        deviceDetails,
        page,
        text,
        category,
        orderId,
        travelIssues,
    } = feedback

    const tags = [
        [deviceDetails?.os, deviceDetails?.systemVersion],
        [deviceDetails?.browser, deviceDetails?.browserVersion],
        [deviceDetails?.manufacturer, deviceDetails?.deviceModel],
        [deviceDetails?.appVersion],
    ]
        .map((tag) => tag.filter(Boolean).join(' '))
        .filter(Boolean)

    const renderCategoryLine = (text: string, cat: ReactNode | string) => (
        <div className="feedback__category">
            <StrongText>{text}:</StrongText> {cat}
        </div>
    )

    const customerText = feedback.customerNumber
        ? `fra kundenr: ${feedback.customerNumber} `
        : 'fra ikke-anonym bruker '
    const descriptionText = anonymous ? 'av anonym bruker ' : customerText

    return (
        <div className="feedback__message">
            <Paragraph className="feedback__message-text">{text}</Paragraph>

            <SubParagraph>
                Sendt inn
                <EmphasizedText>
                    {` ${formatDateTimeLong(createdAt)} `}
                </EmphasizedText>
                {descriptionText}
                <StrongText>{getSentFrom(page)}</StrongText>.
            </SubParagraph>

            <div className="feedback__tags">
                {tags.map((tag) => (
                    <Tag className="feedback__tag" key={tag}>
                        {tag}
                    </Tag>
                ))}
            </div>

            {renderCategoryLine('Kategori', category)}

            {orderId &&
                renderCategoryLine(
                    'Ordre',
                    <Link
                        href={`https://ops-center.entur.io/orders/${orderId}`}
                        target="_blank"
                    >
                        {orderId}
                    </Link>,
                )}

            {travelIssues && renderCategoryLine('Info', travelIssues)}
        </div>
    )
}

interface Props {
    feedback: Feedback
}
