import React, { useState, useEffect } from 'react'
import { Map, TileLayer, Polyline } from 'react-leaflet'
import Leaflet, { LatLngExpression } from 'leaflet'

import { Heading2 } from '@entur/typography'
import { decode } from '@mapbox/polyline'
import { colors } from '@entur/tokens'

import {
    SearchLocation,
    SearchParams,
    TripPattern,
    Mode,
    InputCoordinates,
} from '../../../types'

const DEFAULT_CENTER: [number, number] = [60, 10]

function getTransportColor(mode: Mode): string {
    return (
        // @ts-ignore
        colors.transport.default[mode] || colors.transport.default.walk
    )
}

function getCoordinates(
    location?: SearchLocation,
): InputCoordinates | null | undefined {
    return location === 'MY_LOCATION' ? undefined : location?.coordinates
}
interface MapData {
    color: string
    polyline: LatLngExpression[]
}

function getMapData(tripPatterns: TripPattern[]): MapData[] {
    return tripPatterns
        .flatMap(({ legs }) => legs)
        .map((leg) => {
            const polyline = decode(
                leg.pointsOnLink?.points || '',
            ) as LatLngExpression[]

            return { polyline, color: getTransportColor(leg.mode) }
        })
}

export default function FeedbackMap({ tripPatterns, searchParams }: Props) {
    const [mapData, setMapData] = useState<MapData[]>(getMapData(tripPatterns))

    const [bounds, setBounds] = useState<Leaflet.LatLngBounds | undefined>(
        undefined,
    )

    useEffect(() => {
        const data = getMapData(tripPatterns)
        setMapData(data)
    }, [tripPatterns])

    useEffect(() => {
        const fromCoordinates = getCoordinates(searchParams?.from)
        const toCoordinates = getCoordinates(searchParams?.to)

        if (!searchParams || !fromCoordinates || !toCoordinates) {
            return
        }

        const newBounds = Leaflet.latLngBounds(
            {
                lat: fromCoordinates?.latitude,
                lng: fromCoordinates?.longitude,
            },
            {
                lat: toCoordinates?.latitude,
                lng: toCoordinates?.longitude,
            },
        )

        setBounds(newBounds)
    }, [searchParams])

    return (
        <div className="feedback__map">
            <Heading2>Kart</Heading2>
            <Map
                center={DEFAULT_CENTER}
                style={{ height: '80vh' }}
                zoom={10}
                zoomControl={false}
                bounds={bounds}
                useFlyTo
                boundsOptions={{
                    animate: true,
                    duration: 2,
                    paddingTopLeft: [40, 40],
                    paddingBottomRight: [40, 40],
                }}
            >
                <TileLayer
                    attribution={
                        '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
                    }
                    url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
                />
                {mapData.map(({ color, polyline }, index) => (
                    <Polyline
                        key={`map-data-${index}`}
                        color={color}
                        positions={polyline}
                    />
                ))}
            </Map>
        </div>
    )
}

interface Props {
    tripPatterns: TripPattern[]
    searchParams?: SearchParams
}
