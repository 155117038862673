import React from 'react'

import { TravelTag, TravelLeg } from '@entur/travel'
import { Heading2, Heading3, SmallText } from '@entur/typography'

import { formatTime, formatDateTimeLong } from '../../../utils/time'
import { TripPattern } from '../../../types'

import { getTransportIcon, getAuthorityDescription } from './helper'
import './styles.css'

interface Props {
    tripPattern: TripPattern
}

export default function TravelDetail({ tripPattern }: Props) {
    return (
        <div className="detail">
            <Heading2>Reisedetaljer</Heading2>

            <div className="detail__date">
                <SmallText>
                    {formatDateTimeLong(tripPattern.expectedStartTime)}
                </SmallText>
            </div>

            {tripPattern.legs.map((leg, index) => {
                const isLastLeg = index === tripPattern.legs.length - 1

                return (
                    <div
                        key={formatTime(leg.aimedStartTime)}
                        className="detail__leg"
                    >
                        <div className="detail__time">
                            <SmallText>
                                {formatTime(leg.aimedStartTime)}
                            </SmallText>

                            {isLastLeg ? (
                                <div>
                                    <SmallText>
                                        {formatTime(leg.aimedEndTime)}
                                    </SmallText>
                                </div>
                            ) : null}
                        </div>

                        <TravelLeg
                            // @ts-ignore
                            transport={leg.mode}
                            direction="vertical"
                            className="detail__travel-leg"
                            showStop={index === tripPattern.legs.length - 1}
                        />

                        <div>
                            <Heading3>{leg.fromPlace.name}</Heading3>

                            {leg.mode !== 'foot' && (
                                <div className="detail__authority">
                                    {getAuthorityDescription(leg)}
                                </div>
                            )}
                            <div className="detail__leg__line">
                                <TravelTag>
                                    {getTransportIcon(leg.mode)}
                                    {leg.line?.publicCode}{' '}
                                </TravelTag>

                                <span className="detail__front-text">
                                    {leg.mode === 'foot'
                                        ? `Gå ${Math.round(
                                              leg.duration / 60,
                                          )} minutter (${Math.round(
                                              leg.distance,
                                          )} m)`
                                        : leg.fromEstimatedCall
                                              ?.destinationDisplay?.frontText}
                                </span>
                            </div>

                            {isLastLeg ? (
                                <Heading3>{leg.toPlace.name}</Heading3>
                            ) : null}
                        </div>
                    </div>
                )
            })}

            <div className="detail__date">
                <SmallText>
                    {formatDateTimeLong(tripPattern.expectedEndTime)}
                </SmallText>
            </div>
        </div>
    )
}
