import React from 'react'
import { Router, Switch, Route } from 'react-router-dom'
import { Auth0Provider, withAuthenticationRequired } from '@auth0/auth0-react'
import { createBrowserHistory } from 'history'

import { getAuthConfigForFeedback } from './utils/authentication'

import Feedback from './pages/Feedback'

import './App.css'

export const history = createBrowserHistory()

const ProtectedRoute = ({ component, ...args }: any) => (
    <Route component={withAuthenticationRequired(component)} {...args} />
)

const onRedirectCallback = (appState: any): void => {
    history.replace(appState?.returnTo || window.location.pathname)
}

const config = getAuthConfigForFeedback()

function App() {
    return (
        <Auth0Provider
            domain={config.domain}
            clientId={config.clientId}
            authorizationParams={{
                redirectUri: window.location.origin + config.redirectUri,
                audience: config.audience,
            }}
            cacheLocation="localstorage"
            onRedirectCallback={onRedirectCallback}
        >
            <Router history={history}>
                <div className="app">
                    <div className="page">
                        <Switch>
                            <ProtectedRoute
                                path="/feedback/:id"
                                component={Feedback}
                            />
                            <ProtectedRoute path="/">
                                <Feedback />
                            </ProtectedRoute>
                        </Switch>
                    </div>
                </div>
            </Router>
        </Auth0Provider>
    )
}

export default App
