import React from 'react'

import { PreformattedText, Link } from '@entur/typography'
import { ExpandablePanel } from '@entur/expand'

import { Feedback } from '../../types'

import './styles.css'

export default function BigQueryLink({ feedback }: Props) {
    const { id, createdAt } = feedback

    if (!id || !createdAt) return null

    return (
        <ExpandablePanel title="BigQuery">
            <Link
                href="
https://console.cloud.google.com/bigquery?sq=156489738214:672d2d7d28bb451c9e5c978985a4c9aa&project=entur-prod
"
                target="_blank"
            >
                Lenke til BigQuery
            </Link>
            <PreformattedText>
                {`SELECT *
FROM (
SELECT *
FROM \`entur-prod.analytics_196113150.events_${createdAt
                    .slice(0, 10)
                    .replace(/-/g, '')}\`,
UNNEST(event_params) AS param
WHERE event_name = 'SEND_FEEDBACK_FULFILLED' and param.key = "id" and param.value.string_value = "${id}"
)`}
            </PreformattedText>
        </ExpandablePanel>
    )
}

interface Props {
    feedback: Feedback
}
