import { Environment, getEnvironment } from './environment'

export function getAuthConfigForFeedback(): AuthConfig {
    const env = getEnvironment()

    switch (env) {
        case Environment.PROD:
            return {
                domain: 'partner.entur.org',
                clientId: 'XN4cVTEn1JCkPftioYpP5YUuZj8I0Xii',
                audience: 'https://feedback.entur.org',
                redirectUri: '/callback',
            }

        case Environment.STAGING:
        case Environment.STAGING_LOCAL:
            return {
                domain: 'partner.staging.entur.org',
                clientId: 'srDpYqwxg428PoWXBVtyGBCSICLnuFBT',
                audience: 'https://feedback.staging.entur.org',
                redirectUri: '/callback',
            }
    }
}

interface AuthConfig {
    domain: string
    clientId: string
    audience: string
    redirectUri: string
}
