import { useEffect, useState, useCallback } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

import { Feedback } from '../../types'

import { Environment, getEnvironment } from '../../utils/environment'

export function useFeedback(id: string) {
    const [loading, setLoading] = useState(false)
    const [feedback, setFeedback] = useState<Feedback | undefined>()
    const [error, setError] = useState<Error | undefined>()
    const closeError = () => setError(undefined)
    const { getAccessTokenSilently } = useAuth0()

    const url = `${getFeedbackUrl()}/${id}`

    const fetchFeedback = useCallback(async () => {
        setLoading(true)

        getAccessTokenSilently().then((accessToken) => {
            fetch(url, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
            })
                .then((response) => {
                    if (response.ok) return response.json()
                })
                .then(setFeedback)
                .catch(setError)
                .finally(() => setLoading(false))
        })
    }, [id, url])

    useEffect(() => {
        if (id) fetchFeedback()
    }, [fetchFeedback, id])

    return { feedback, loading, error, closeError }
}

function getFeedbackUrl(): string {
    const env = getEnvironment()
    const endpoint = 'api/v1'

    switch (env) {
        case Environment.PROD:
            return `https://europe-west1-entur-feedback.cloudfunctions.net/${endpoint}`
        case Environment.STAGING:
            return `https://europe-west1-entur-feedback-staging.cloudfunctions.net/${endpoint}`
        case Environment.STAGING_LOCAL:
            return `http://localhost:5001/entur-feedback-staging/europe-west1/${endpoint}`

        // Use this if running PROD functions locally
        // return `http://localhost:5001/entur-feedback/europe-west1/${endpoint}`
    }
}
