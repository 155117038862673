export interface Coordinates {
    latitude: number
    longitude: number
}
export interface Location {
    name?: string
    place?: string
    coordinates?: Coordinates
}

export interface TripPatternSDK {
    /** Total distance for the trip, in meters. */
    distance: number
    /**
     * This sums the direct durations of each leg. Be careful about using this,
     * as it is not equal to the duration between startTime and endTime.
     * See the directDuration documentation on Leg.
     * */
    directDuration: number
    /** Duration of the trip, in seconds. */
    duration: number
    /** @deprecated Use expectedEndTime instead */
    endTime: string
    /** The expected, realtime adjusted date and time the trip ends. */
    expectedEndTime: string
    /** The expected, realtime adjusted date and time the trip starts. */
    expectedStartTime: string
    id?: string
    legs: Leg[]
    /** @deprecated Use expectedStartTime instead */
    startTime: string
    /** How far the user has to walk, in meters. */
    walkDistance: number
}

export enum TransportSubmode {
    SchengenAreaFlight = 'SchengenAreaFlight',
    AirportBoatLink = 'airportBoatLink',
    AirportLinkBus = 'airportLinkBus',
    AirportLinkRail = 'airportLinkRail',
    AirshipService = 'airshipService',
    AllFunicularServices = 'allFunicularServices',
    AllHireVehicles = 'allHireVehicles',
    AllTaxiServices = 'allTaxiServices',
    BikeTaxi = 'bikeTaxi',
    BlackCab = 'blackCab',
    CableCar = 'cableCar',
    CableFerry = 'cableFerry',
    CanalBarge = 'canalBarge',
    CarTransportRailService = 'carTransportRailService',
    ChairLift = 'chairLift',
    CharterTaxi = 'charterTaxi',
    CityTram = 'cityTram',
    CommunalTaxi = 'communalTaxi',
    CommuterCoach = 'commuterCoach',
    CrossCountryRail = 'crossCountryRail',
    DedicatedLaneBus = 'dedicatedLaneBus',
    DemandAndResponseBus = 'demandAndResponseBus',
    DomesticCharterFlight = 'domesticCharterFlight',
    DomesticFlight = 'domesticFlight',
    DomesticScheduledFlight = 'domesticScheduledFlight',
    DragLift = 'dragLift',
    ExpressBus = 'expressBus',
    Funicular = 'funicular',
    HelicopterService = 'helicopterService',
    HighFrequencyBus = 'highFrequencyBus',
    HighSpeedPassengerService = 'highSpeedPassengerService',
    HighSpeedRail = 'highSpeedRail',
    HighSpeedVehicleService = 'highSpeedVehicleService',
    HireCar = 'hireCar',
    HireCycle = 'hireCycle',
    HireMotorbike = 'hireMotorbike',
    HireVan = 'hireVan',
    IntercontinentalCharterFlight = 'intercontinentalCharterFlight',
    IntercontinentalFlight = 'intercontinentalFlight',
    International = 'international',
    InternationalCarFerry = 'internationalCarFerry',
    InternationalCharterFlight = 'internationalCharterFlight',
    InternationalCoach = 'internationalCoach',
    InternationalFlight = 'internationalFlight',
    InternationalPassengerFerry = 'internationalPassengerFerry',
    InterregionalRail = 'interregionalRail',
    Lift = 'lift',
    Local = 'local',
    LocalBus = 'localBus',
    LocalCarFerry = 'localCarFerry',
    LocalPassengerFerry = 'localPassengerFerry',
    LocalTram = 'localTram',
    LongDistance = 'longDistance',
    Metro = 'metro',
    MiniCab = 'miniCab',
    MobilityBus = 'mobilityBus',
    MobilityBusForRegisteredDisabled = 'mobilityBusForRegisteredDisabled',
    NationalCarFerry = 'nationalCarFerry',
    NationalCoach = 'nationalCoach',
    NationalPassengerFerry = 'nationalPassengerFerry',
    NightBus = 'nightBus',
    NightRail = 'nightRail',
    PostBoat = 'postBoat',
    PostBus = 'postBus',
    RackAndPinionRailway = 'rackAndPinionRailway',
    RailReplacementBus = 'railReplacementBus',
    RailShuttle = 'railShuttle',
    RailTaxi = 'railTaxi',
    RegionalBus = 'regionalBus',
    RegionalCarFerry = 'regionalCarFerry',
    RegionalCoach = 'regionalCoach',
    RegionalPassengerFerry = 'regionalPassengerFerry',
    RegionalRail = 'regionalRail',
    RegionalTram = 'regionalTram',
    ReplacementRailService = 'replacementRailService',
    RiverBus = 'riverBus',
    RoadFerryLink = 'roadFerryLink',
    RoundTripCharterFlight = 'roundTripCharterFlight',
    ScheduledFerry = 'scheduledFerry',
    SchoolAndPublicServiceBus = 'schoolAndPublicServiceBus',
    SchoolBoat = 'schoolBoat',
    SchoolBus = 'schoolBus',
    SchoolCoach = 'schoolCoach',
    ShortHaulInternationalFlight = 'shortHaulInternationalFlight',
    ShuttleBus = 'shuttleBus',
    ShuttleCoach = 'shuttleCoach',
    ShuttleFerryService = 'shuttleFerryService',
    ShuttleFlight = 'shuttleFlight',
    ShuttleTram = 'shuttleTram',
    SightseeingBus = 'sightseeingBus',
    SightseeingCoach = 'sightseeingCoach',
    SightseeingFlight = 'sightseeingFlight',
    SightseeingService = 'sightseeingService',
    SightseeingTram = 'sightseeingTram',
    SleeperRailService = 'sleeperRailService',
    SpecialCoach = 'specialCoach',
    SpecialNeedsBus = 'specialNeedsBus',
    SpecialTrain = 'specialTrain',
    StreetCableCar = 'streetCableCar',
    SuburbanRailway = 'suburbanRailway',
    Telecabin = 'telecabin',
    TelecabinLink = 'telecabinLink',
    TouristCoach = 'touristCoach',
    TouristRailway = 'touristRailway',
    TrainFerry = 'trainFerry',
    TrainTram = 'trainTram',
    Tube = 'tube',
    Undefined = 'undefined',
    UndefinedFunicular = 'undefinedFunicular',
    Unknown = 'unknown',
    UrbanRailway = 'urbanRailway',
    WaterTaxi = 'waterTaxi',
}

export enum Mode {
    Air = 'air',
    Bus = 'bus',
    Cableway = 'cableway',
    Coach = 'coach',
    Funicular = 'funicular',
    Lift = 'lift',
    Metro = 'metro',
    Monorail = 'monorail',
    Rail = 'rail',
    Tram = 'tram',
    Trolleybus = 'trolleybus',
    Unknown = 'unknown',
    Water = 'water',
    Foot = 'foot',
}

export type Leg = {
    aimedStartTime: string
    aimedEndTime: string
    duration: number
    distance: number
    fromPlace: {
        name?: string
    }
    fromEstimatedCall?: {
        destinationDisplay?: {
            frontText?: string
        }
    }
    toPlace: {
        name?: string
    }
    mode: Mode
    transportSubmode?: TransportSubmode
    line?: {
        publicCode?: string
    }
    authority?: {
        name?: string
    }
    operator?: {
        name?: string
    }
    pointsOnLink?: {
        points?: string
    }
}

export type TripPattern = {
    id: string
    expectedStartTime: string
    expectedEndTime: string
    duration?: number
    legs: Leg[]
}

export interface Feedback {
    id: string
    text: string
    page: FeedbackPage
    deviceDetails: DeviceDetails
    notificationEmail?: string
    anonymous: boolean
    travelSearch?: TravelSearch
    createdAt: string
    category?: string
    orderId?: string
    travelIssues?: string
    customerNumber?: string
}

export interface TravelSearch {
    searchParams?: SearchParams
    tripPattern?: TripPattern
    tripPatterns?: TripPattern[]
}

export interface FeedbackRequest {
    text: string
    page: FeedbackPage
    deviceDetails: DeviceDetails
    notificationEmail?: string
    searchParams: SearchParams
    travelDetailPattern?: TripPatternSDK & { id: string }
    travelResultPatterns?: Array<TripPatternSDK & { id: string }>
    nonTransitPatterns?: Array<TripPatternSDK & { id: string }>
    category?: string
    orderId?: string
    travelIssues?: string
    customerNumber?: string
}

export type FeedbackPage =
    | 'Home'
    | 'TravelResult'
    | 'TravelDetails'
    | 'MyDetails'

export interface DeviceDetails {
    appVersion: string
    browser: string
    browserVersion?: string
    deviceId: string
    deviceModel: string
    manufacturer: string
    os: string
    platform: string
    systemVersion?: string
}

export interface SearchParams {
    from: SearchLocation
    to: SearchLocation
    searchDate: string
    arriveBy?: boolean
    searchFilters?: SearchFilter[]
    walkSpeed?: number
    minimumTransferTime?: number
    walkReluctance?: number
}

export type SearchFilter =
    | 'rail'
    | 'metro'
    | 'tram'
    | 'bus'
    | 'water'
    | 'air'
    | 'flytog'
    | 'flybuss'

export type SearchLocation = Location | 'MY_LOCATION'

export type InputCoordinates = {
    /** The latitude of the place. */
    latitude: Scalars['Float']
    /** The longitude of the place. */
    longitude: Scalars['Float']
}

export type Scalars = {
    ID: string
    String: string
    Boolean: boolean
    Int: number
    Float: number
    /** List of coordinates like: [[60.89, 11.12], [62.56, 12.10]] */
    Coordinates: Array<[lat: number, lon: number]>
    /** Local date using the ISO 8601 format: `YYYY-MM-DD`. Example: `2020-05-17`. */
    Date: string
    /**
     * DateTime format accepting ISO 8601 dates with time zone offset.
     *
     * Format:  `YYYY-MM-DD'T'hh:mm[:ss](Z|±01:00)`
     *
     * Example: `2017-04-23T18:25:43+02:00` or `2017-04-23T16:25:43Z`
     */
    DateTime: string
    /** A linear function to calculate a value(y) based on a parameter (x): `y = f(x) = a + bx`. It allows setting both a constant(a) and a coefficient(b) and the use those in the computation. Format: `a + b x`. Example: `1800 + 2.0 x` */
    DoubleFunction: string
    /** Time using the format: HH:mm:SS. Example: 18:25:SS */
    LocalTime: string
    /** Long type */
    Long: number
    /** Time using the format: `HH:MM:SS`. Example: `18:25:43` */
    Time: string
}
