import React, { useState, useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { InputGroup, TextField } from '@entur/form'
import { BannerAlertBox } from '@entur/alert'
import { PrimaryButton } from '@entur/button'
import { Heading1 } from '@entur/typography'
import { Loader } from '@entur/loader'

import TravelDetail from './TravelDetail'
import TravelResult from './TravelResult'
import BigQueryLink from './BigQueryLink'
import SearchParams from './SearchParams'
import { useFeedback } from './hooks'
import Message from './Message'
import Map from './Map'
import './styles.css'

export default function Feedback() {
    const params = useParams<{ id: string }>()
    const history = useHistory()

    const [id, setId] = useState(params?.id)
    const [textValue, setTextValue] = useState(params?.id || '')
    const { loading, feedback, error, closeError } = useFeedback(id)

    const submit = useCallback(
        (event) => {
            event.preventDefault()
            setId(textValue)
            history.replace(`/feedback/${textValue}`)
        },
        [history, textValue],
    )

    const header = (
        <div>
            <header>
                <Heading1>Tilbakemelding {id}</Heading1>
            </header>

            <form className="feedback__form" onSubmit={submit}>
                <InputGroup label="Tilbakemeldings-ID">
                    <TextField
                        placeholder="ABCD3"
                        value={textValue}
                        onChange={(e: React.SyntheticEvent<HTMLInputElement>) =>
                            setTextValue(e.currentTarget.value.toUpperCase())
                        }
                    />
                </InputGroup>
                <PrimaryButton className="feedback__button">Hent</PrimaryButton>
            </form>

            {loading && <Loader>Henter tilbakemelding</Loader>}

            {error && (
                <BannerAlertBox variant="error" closable onClose={closeError}>
                    {error.message}
                </BannerAlertBox>
            )}
        </div>
    )

    if (!feedback) {
        return (
            <div className="feedback">
                <div className="feedback__column">{header}</div>
            </div>
        )
    }

    const { searchParams, tripPattern, tripPatterns } =
        feedback.travelSearch || {}

    return (
        <div className="feedback">
            <div className="feedback__column">
                {header}

                <Message feedback={feedback} />

                {searchParams && <SearchParams searchParams={searchParams} />}

                <BigQueryLink feedback={feedback} />
            </div>

            {tripPattern && (
                <div className="feedback__column-offset">
                    <div className="feedback__column">
                        <TravelDetail tripPattern={tripPattern} />
                    </div>

                    <div className="feedback__column">
                        <Map
                            tripPatterns={[tripPattern]}
                            searchParams={searchParams}
                        />
                    </div>
                </div>
            )}

            {tripPatterns && (
                <div className="feedback__column-offset">
                    <div className="feedback__column">
                        <TravelResult tripPatterns={tripPatterns} />
                    </div>

                    <div className="feedback__column">
                        <Map
                            tripPatterns={tripPatterns}
                            searchParams={searchParams}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}
