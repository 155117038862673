import React from 'react'

import { TravelSwitchProps, TravelSwitch } from '@entur/form'
import { Heading2, Paragraph } from '@entur/typography'
import { TravelHeader } from '@entur/travel'
import { ClockIcon } from '@entur/icons'

import {
    SearchParams as SearchParamsType,
    SearchLocation,
    SearchFilter,
} from '../../types'

import { formatDateTimeLong } from '../../utils/time'

import './styles.css'

const ALL_FILTERS: SearchFilter[] = [
    'rail',
    'metro',
    'tram',
    'bus',
    'water',
    'air',
    'flytog',
    'flybuss',
]

function toTransportMode(filter: SearchFilter): TravelSwitchProps['transport'] {
    switch (filter) {
        case 'flytog':
            return 'airportLinkRail'
        case 'flybuss':
            return 'airportLinkBus'
        default:
            return filter
    }
}

function toLocationName(location: SearchLocation): string {
    return location === 'MY_LOCATION'
        ? 'Posisjonen din'
        : location.name || 'UKJENT'
}

export default function SearchParams({ searchParams }: Props) {
    const { from, to, arriveBy, searchDate, searchFilters } = searchParams
    const areAllFiltersEnabled = !searchFilters

    const toTravelSwitch = (filter: SearchFilter) => (
        <TravelSwitch
            key={filter}
            transport={toTransportMode(filter)}
            readOnly
            checked={areAllFiltersEnabled || searchFilters?.includes(filter)}
        >
            {filter}
        </TravelSwitch>
    )

    return (
        <div className="feedback__params">
            <Heading2>Reisesøk</Heading2>

            <TravelHeader
                className="feedback__travel-header"
                from={toLocationName(from)}
                to={toLocationName(to)}
            />

            <Paragraph className="feedback__arrival">
                <ClockIcon className="feedback__clock" />
                {`${arriveBy ? 'Ankomst' : 'Avreise'} ${formatDateTimeLong(
                    searchDate,
                )}`}
            </Paragraph>

            <div className="feedback__filters">
                {ALL_FILTERS.map(toTravelSwitch)}
            </div>
        </div>
    )
}

interface Props {
    searchParams: SearchParamsType
}
